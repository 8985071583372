var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tui tuim ui user top right pointing dropdown item" },
    [
      _c(
        "div",
        {
          staticClass: "wrap-nav-dropdown",
          class: { "has-avatar": _vm.avatar }
        },
        [
          _c(
            "span",
            { staticClass: "name-ong" },
            [
              _vm._v("Opções "),
              _c("t-icon", {
                staticClass: "icon-dropdown",
                attrs: { icon: ["far", "chevron-down"] }
              })
            ],
            1
          ),
          _vm.user.avatar
            ? _c("div", { staticClass: "image" }, [
                _c("img", {
                  staticClass: "logo-ong",
                  attrs: { src: _vm.avatar, alt: "Logo de " + _vm.user.name }
                })
              ])
            : _vm._e()
        ]
      ),
      _c(
        "div",
        { staticClass: "menu" },
        [
          _c("div", { staticClass: "tui tuim ui card" }, [
            _vm.user.avatar
              ? _c("a", { staticClass: "image", attrs: { href: "#" } }, [
                  _c("img", {
                    attrs: {
                      src: _vm.avatar,
                      alt: "Foto de " + _vm.user.full_name
                    }
                  })
                ])
              : _vm._e(),
            _c("div", { staticClass: "content" }, [
              _vm.user.full_name
                ? _c("a", {
                    staticClass: "header",
                    domProps: { textContent: _vm._s(_vm.user.full_name) }
                  })
                : _vm._e(),
              _vm.user.email
                ? _c("a", {
                    staticClass: "description",
                    attrs: { href: "#" },
                    domProps: {
                      textContent: _vm._s(_vm.user.email.toLowerCase())
                    }
                  })
                : _vm._e()
            ])
          ]),
          _c("div", { staticClass: "divider" }),
          _vm._l(_vm.menuItems, function(item, i) {
            return _c(
              "router-link",
              { key: i, staticClass: "item", attrs: { to: item.to } },
              [
                _c("t-icon", {
                  staticClass: "tui tuim icon",
                  attrs: { icon: ["far", item.icon] }
                }),
                _c("span", { domProps: { textContent: _vm._s(item.title) } })
              ],
              1
            )
          }),
          _vm.menuItems.length > 0
            ? _c("div", { staticClass: "divider" })
            : _vm._e(),
          _c(
            "div",
            { staticClass: "item config-menu" },
            [
              _c("t-icon", {
                staticClass: "tui tuim icon",
                attrs: { icon: ["fa", "tools"] }
              }),
              _c("router-link", {
                attrs: { tag: "span", to: "/paymentconfig" },
                domProps: { textContent: _vm._s("Configuração de Carteira") }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "item config-menu", on: { click: _vm.logoutUser } },
            [
              _c("t-icon", {
                staticClass: "tui tuim icon",
                attrs: { icon: ["far", "sign-out-alt"] }
              }),
              _c("span", [_vm._v("Sair")])
            ],
            1
          )
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }