<template>
  <div class="tui tuim ui user top right pointing dropdown item">
    <div class="wrap-nav-dropdown" :class="{'has-avatar' : avatar}">
      <span class="name-ong">Opções <t-icon class="icon-dropdown" :icon="[ 'far', 'chevron-down' ]" /></span>

      <div v-if="user.avatar" class="image">
        <img class="logo-ong" :src="avatar" :alt="`Logo de ${user.name}`" />
      </div>

    </div>

    <div class="menu">

      <div class="tui tuim ui card">
        <!-- <a href="#" class="edit"><t-icon :icon="[ 'far', 'edit' ]" class="tui tuim icon" /></a> -->
        <a v-if="user.avatar" href="#" class="image">
          <img :src="avatar" :alt="`Foto de ${user.full_name}`" />
        </a>
        <div class="content">
          <a class="header" v-if="user.full_name" v-text="user.full_name" />
          <a href="#" class="description" v-if="user.email" v-text="user.email.toLowerCase()" />
          <!-- <p class="meta">Gerencia a Nome da ONG extremamente grande</p> -->
        </div>
      </div>

      <div class="divider" />

      <router-link :to="item.to" v-for="(item, i) in menuItems" :key="i" class="item">
        <t-icon :icon="[ 'far', item.icon ]" class="tui tuim icon" />
        <span v-text="item.title" />
      </router-link>

      <div v-if="menuItems.length > 0" class="divider" />

      <div class="item config-menu">
        <t-icon :icon="[ 'fa', 'tools' ]" class="tui tuim icon" />
        <router-link
          tag="span"
          :to="'/paymentconfig'"
          v-text="'Configuração de Carteira'"
        />
      </div>

      <div class="item config-menu" @click="logoutUser">
        <t-icon :icon="[ 'far', 'sign-out-alt' ]" class="tui tuim icon" />
        <span>Sair</span>
      </div>

    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'THeaderUserNav',
  data () {
    return {
      avatar: require('@/assets/avatar-woman.svg'),
      menuItems: [
        // { to: '#', title: 'Meu perfil', icon: 'user' },
        // { to: '#', title: 'Alternar conta', icon: 'home-alt' },
        // { to: '#', title: 'Configurações', icon: 'cog' },
        // { to: '#', title: 'Enviar feedback', icon: 'comment-alt' },
        // { to: '#', title: 'Ajuda', icon: 'info-circle' }
      ]
    }
  },
  mounted () {
    $('.user.dropdown').dropdown()
    $('.user.dropdown').on('click', function() {
      let menus = $('.menu .item.config-menu')
      if (menus.hasClass('active selected')) {
        menus.removeClass('active selected')
      }
    })
  },
  computed: {
    ...mapGetters({
      user: 'getUser'
    })
  },
  methods: {
    ...mapActions(['logout']),
    logoutUser (e) {
      e.preventDefault()

      this.logout()
        .then(resp => this.$router.push({ name: 'auth.login' }))
        .catch(err => this.$log.error('error', err))
    }
  }
}
</script>

<style lang="scss">
.tui.tuim.ui.dropdown {
  &.user {
    margin: 20px 20px 20px 0;
    padding: 0;
    line-height: 40px;
    color: #aeadad;
    border: $border;
    border-radius: 3px;

    .item {
      padding: 10px 20px !important;
    }

    .name-ong {
      font-size: 12px;
      font-weight: $font-weight-bold;
      color: $black-0;
      padding-right: 10px;

      .icon-dropdown {
        color: #aeadad;
        margin-left: 5px;
      }
    }

    .wrap-nav-dropdown {
      align-items: center;
      display: flex;
      justify-content: center;
      padding: 0 15px;

      &.has-avatar {
        padding-right: 0;
      }

      .image {
        max-width: 40px;
        height: 40px;

        .logo-ong {
          width: 100%;
          display: block;
          height: auto;
        }
      }
    }

    &::before {
      display: none;
    }

    .menu > .divider {
      margin: 0;
    }

    .menu > .tui.tuim.ui.card {
      width: 270px;
      border-radius: 0;
      box-shadow: none;
      text-align: center;
      padding: 20px 20px 10px;
      border: 0;
      margin-bottom: 0;

      .edit {
        position: absolute;
        top: 10px;
        right: 20px;
      }

      * {
        white-space: normal;
      }

      .image {
        width: 60px;
        display: block;
        margin: 0 auto;
        border-radius: 4px !important;
        background-color: #fdda96;
      }

      .header {
        font-size: 18px;
        font-weight: $font-weight-bold;
        line-height: 1;
        color: $black-0;
        margin: 10px 0;
      }

      .content {
        border: 0;
        padding: {
          top: 0;
          bottom: 20px;
        }
      }

      .description {
        color: $black-0;
      }

      .meta {
        color: #a3a3a3;
      }

      .meta,
      .description {
        display: block;
        margin: 4px 0;
        font-size: 12px;
        line-height: 1.33;
      }
    }
  }
}
</style>
